.login-form {
    max-width: 300px;
}
.login-form-forgot {
    float: right;
}
.ant-col-rtl .login-form-forgot {
    float: left;
}
.login-form-button {
    width: 100%;
}

.login-form-wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}